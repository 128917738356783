<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách bệnh nghề nghiệp</h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :select-options="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          style-class="vgt-table"
        >
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} bệnh nghề nghiệp</p>
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách bệnh nghề nghiệp không hợp lệ</h4>
        </div>
        <div class="listwork__head__02__right">

          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit()"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid()"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
          <div />
        </div>

      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->
            <span v-if="props.column.field == 'code' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Code'? 'text-danger' :'' "
              >{{ props.row.code }}</span>
            </span>

            <span v-if="props.column.field == 'code' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Code'"
                v-model="props.row.code"
                type="text"
                class="cssInput"
                @input="changeCellvalue($event, 'code', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Code'"
              >{{ props.row.code }}</span>
            </span>

            <span v-if="props.column.field == 'name' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Name'? 'text-danger' :'' "
              >{{ props.row.name }}</span>
            </span>

            <span v-if="props.column.field == 'name' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue($event, 'name', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Name'"
              >{{ props.row.name }}</span>
            </span>

            <span v-if="props.column.field == 'element' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Element'? 'text-danger' :'' "
              >{{ props.row.element }}</span>
            </span>

            <span v-if="props.column.field == 'element' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Element'"
                v-model="props.row.element"
                type="text"
                class="cssInput"
                @input="changeCellvalue($event, 'element', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Element'"
              >{{ props.row.element }}</span>
            </span>

          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} bệnh nghề nghiệp</p>
      </div>

      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addOccupationalDisease()"
          >
            Thêm bệnh nghề nghiệp
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'

export default {
  components: {
    VueGoodTable,
    BButton,
    VSelect,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      model: {},
      valueSelectone: '',
      dataInvalid: [],
      dataNoInvalid: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      columns: [
        {
          label: 'MÃ BỆNH',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN BỆNH NGHỀ NGHIỆP',
          field: 'name',
          sortable: false,
        },
        {
          label: 'YẾU TỐ CÓ HẠI',
          field: 'element',
          sortable: false,
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'MÃ BỆNH',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN BỆNH NGHỀ NGHIỆP',
          field: 'name',
          sortable: false,
        },
        {
          label: 'YẾU TỐ CÓ HẠI',
          field: 'element',
          sortable: false,
        },
      ],
      dataCheck: [],
    }
  },
  computed: {
    ...mapGetters('disease', ['validWork', 'validNoWork', 'dataTooltip']),
  },
  mounted() {
    this.dataInvalid = this.validWork
    this.dataNoInvalid = this.validNoWork
  },
  created() {
    this.checkErrorUser(this.dataTooltip)
  },
  methods: {
    ...mapActions('disease', [
      'getApiExcel',
    ]),
    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event.target.value
    },
    // nút check
    async CheckdataNoInvalid() {
      const model = {
        listExcel: this.dataNoInvalid,
        isValidate: true,
      }
      const valuedata = await this.getApiExcel(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
    },
    // nút hủy bỏ
    close() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'occupational-disease' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addOccupationalDisease() {
      const model = {
        listExcel: this.dataInvalid,
        isValidate: false,
      }
      this.getApiExcel(model).then(res => {
        if (res.status === 200) {
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.dataInvalid = []
          if (this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'occupational-disease' })
          }
        } else {
          this.$root.$bvToast.toast('Thêm không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },

  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
</style>
